import { DIALOG_SIZE } from "@/libs/interfaces/layouts"
import FieldInput, { SCALAR_TYPES } from "@/libs/scalars"
import { VerticalType } from "@/libs/scalars/interface/IScalarForm"
import ConfirmButton from "@/libs/useful/ConfirmButton"
import { __, sprintf, translitterate } from "@/libs/utilities"
import { actions } from "@/modules/pe-fest-module/data/actions"
import { REGISTER_NEW_FESTIVAL } from "@/modules/pe-fest-module/data/actionTypes"
import { Intent } from "@blueprintjs/core"
import { useState } from "react"
import { IAddFestivalTabsProps } from "../AddFestivalTabs"
import { AppToaster } from "@/libs/useful"
import { useNavigate } from "react-router-dom"

/* Protopia Ecosystem component */ 
const PelandFileTab = (props: IAddFestivalTabsProps): JSX.Element => {
    const [file, setFile] = useState<string>("")
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const navigate = useNavigate()

    const onFile = (value:string) => {
        setFile(value)
        console.log( value )
    }
    const onStartCreate = () => {
        if(!props.item.title) {
            AppToaster.show({
                message: __("Need new Festival title"),
                intent: Intent.DANGER
            })
            return;
        }
        setIsLoading(true)
        actions(
            REGISTER_NEW_FESTIVAL, 
            {
                peland: file,
                new_title: props.item.title,
                new_domain: translitterate()( props.item.title, "" ).toLowerCase()
            }
        ) 
        .then(( res ) => {
            const link: string = `/fest/${ res.toString() }`
            AppToaster.show({
                message: sprintf(__("Event %s successfully created."), props.item.title),
                action: {
                    onClick: () => {
                        navigate(link)
                    },
                    intent: Intent.SUCCESS,
                    text: __("Move to"),
                },
                icon: "hand",
                className:"p-5" 
            }) 
        })
        .finally(() => setIsLoading(false))
    }

    return <div className='pe-peland-file-tab-container flex-centered flex-column'>
        <FieldInput
            type={ SCALAR_TYPES.UPLOAD }
            value={ file }
            editable 
            accept=".peland"
            vertical={ VerticalType.VERTICAL }
            title={ __( "get *.peland scenario file" ) }
            limitSize={ 60000 }
            onChange={ onFile }
        />
        <div className="pl-3 pr-4 py-4 w-100">
            <ConfirmButton
                buttonIntent={ !file ? Intent.NONE : Intent.DANGER}
                dialogClasssName={ DIALOG_SIZE.MINIMAL }
                buttonDisabled={ !file }
                buttonMinimal={  !file }
                buttonLabel={__("Start creating right now")}
                buttonLarge
                buttonFill 
                buttonClassName="p-4"
                onConfirm={onStartCreate}
                buttonLoading={isLoading}
            >
                <div className="p-4">
                    {__("Are you really want create new Festival right now?")}
                </div>
            </ConfirmButton> 
        </div>
        
    </div>
}
export default PelandFileTab