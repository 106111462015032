import { GET_SERVICE_OPTIONS_ACTION } from "@/modules/pe-basic-module/data/actionTypes"
import actions from "@/modules/pe-basic-module/data/actions"
import { isRole } from "@/settings/zustand/user" 
import { Role } from "@/libs/interfaces/layouts"
import { __ } from "@/libs/utilities"
import { useEffect, useState } from "react"
import { NavLink } from "react-router-dom"

/* Protopia Ecosystem component */
export interface IFestAdminMenuProps {

}
const FestAdminMenu = (props: IFestAdminMenuProps): JSX.Element => {
    const [ roles, setRoles ] = useState<Role[]>([])
    useEffect(() => { 
        actions(GET_SERVICE_OPTIONS_ACTION, {})
            .then((resp: any) => { 
                console.log(resp.roles )
                setRoles([ ...resp.roles, "SuperAdmin" ])
            })
    }, [])
    return isRole( roles ) 
        ?
        <div className='pe-fest-admin-menu-container container flex-centered pb-4'>
            <div className="btn-group flex-wrap flex-centered">
                <NavLink to="" className={"btn btn-sm btn-outline-secondary pl-4 pr-3 rounded-pill-0 rounded-pill-3"} >
                    {__("All Sites")} 
                </NavLink> 
                {
                    isRole( "administrator" ) &&
                    <NavLink to="settings" className={"btn btn-sm btn-outline-secondary px-3 "} >
                        {__("Settings")} 
                    </NavLink>
                } 
                <NavLink to="add-festival" className={"btn btn-sm btn-outline-secondary pr-4 pl-3 rounded-pill-1 rounded-pill-2"} >
                    {__("Create new Event")} 
                </NavLink>
            </div>
        </div>
        :
        <></>
}
export default FestAdminMenu