import { DIALOG_SIZE } from "@/libs/interfaces/layouts"
import FieldInput, { SCALAR_TYPES } from "@/libs/scalars"
import { VerticalType } from "@/libs/scalars/interface/IScalarForm"
import ConfirmButton from "@/libs/useful/ConfirmButton"
import { __ } from "@/libs/utilities"
import actions from "@/modules/pe-basic-module/data/actions"
import { UPDATE_SINGLE_TYPE } from "@/modules/pe-basic-module/data/actionTypes"
import { IProject } from "@/modules/pe-fest-module/data/interfaces"
import { isRole } from "@/settings/zustand/user"
import { ButtonGroup } from "@blueprintjs/core"
import { useState } from "react"
import { useParams } from "react-router"

const DEFAULT_MESSAGE = "You resource banned. Contact the Festival administrators."
const DEFAULT_SHOW_MESSAGE = "You resource showed. Goog luck."

/* Protopia Ecosystem component */
export interface IAdminBanFormProps {
   item: IProject
}
const AdminBanForm = (props: IAdminBanFormProps): JSX.Element => { 
    const {landId} = useParams()
    const [reason, setReason] = useState<string>( props.item.is_verified ? __( DEFAULT_MESSAGE ) : __(DEFAULT_SHOW_MESSAGE) )
    const onHide = () => {
        actions(
			UPDATE_SINGLE_TYPE,
			{
				data_type: "PEFestProject",
				id: props.item.id,
				item: { is_verified: !props.item.is_verified, verified_reason:reason },
				landId
			}
		)
    }
    return isRole([ "administrator", "SuperAdmin", "Track_moderator", "School_guardian" ]) 
        ?
        <div className='pe-admin-ban-form-container d-flex'>
        <ButtonGroup className="d-flex flex-wrap" > 
                <ConfirmButton 
                    dialogClasssName={DIALOG_SIZE.MINIMAL}
                    buttonLabel={__(props.item.is_verified ? "Do revoke" : "Do verify Project")} 
                    buttonMinimal
                    buttonIcon="delete"
                    onConfirm={onHide}
                >
                    <BanActions 
                        item={props.item}
                        reason={reason}
                        setReason={setReason}
                    />
                </ConfirmButton>
            </ButtonGroup>
        </div>
        :
        <></>
}
export default AdminBanForm

interface IBanAction {
    item: IProject
    reason: string
    setReason : (reason: string) => void
}
const BanActions = (props:IBanAction): JSX.Element => {  
    return <div className="p-4">  
        <FieldInput
            type={SCALAR_TYPES.TEXT}
            placeholder={__("Send email text")}
            value={props.reason}
            onChange={ value => props.setReason(value) }
            className={"w-100 h_250"}
            title={__("Send reason")}
            vertical={VerticalType.VERTICAL}
            editable
            notClear
            commentary={__("This text sended to owners")}
        /> 
    </div>
}