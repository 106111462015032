
/* Protopia Ecosystem component */
export interface IFeedContainerProps {
   children?: JSX.Element
   type?: "table" | "div" | "ul"
}
const FeedContainer = (props: IFeedContainerProps): JSX.Element => {
    switch(props.type) {
        case "table": 
            return <td colSpan={30} className="w-100">
                {props.children}
            </td>
        case "ul":
            return <li className="w-100">
                {props.children}
            </li>
        case "div":
        default:
            return <div className="w-100">
                {props.children}
            </div>
    } 
}
export default FeedContainer