import { __ } from "src/libs/utilities"

/* Protopia Ecosystem component */
export interface IChooseAndAddUserProps {
   
}
const ChooseAndAddUser = (props: IChooseAndAddUserProps): JSX.Element => {
    return <div className='pe-choose-and-add-user-container'>
       <div className="lead my-4 text-uppercase">
            { __( "Add user from over Land" ) }
        </div>
        
    </div>
}
export default ChooseAndAddUser