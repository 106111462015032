import { IUser } from "src/settings/zustand"
import { Role } from "../interfaces/layouts"
import Layouts from "../layouts"
import { concatRouting } from "../layouts"

export function isLoggedPage(pathname: string) {
  let states = []
  states = concatRouting()
  const state = states.filter((e, i) => {
    const route = `/${e.route}`
    return e.islogged && route === pathname
  })
  console.log(state)
  return (state.length > 0)
}

export function userModel() {
  return Layouts().app.user_model
}

export function userModelField() {
  const data_type: any = Layouts().app.user_model
  const { apollo_fields } = Layouts().schema[ data_type ]
  return apollo_fields
}

export function userModelKeys() {
  const data_type: any = Layouts().app.user_model
  const { apollo_fields } = Layouts().schema[data_type]
  const fields = Object.keys(apollo_fields)
    .filter((e) => e !== "_id" && !apollo_fields[e].hidden)
  return fields
}

export function isCapability(capability: Role[], user: IUser ): boolean {
  if (!(capability && Array.isArray(capability))) {
    return false
  }
  if( capability && Array.isArray(capability) && capability.length === 0)
  {
    // console.log("capabilities empty")
    return false
  }
  if ( user?.roles ) { 
    return !(capability.filter( (role: Role) => user.roles.includes(role) ).length > 0)
  } 
  return true 
  
}