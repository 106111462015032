import { AppToaster } from '@/libs/useful'
import { __ } from "@/libs/utilities"
import React, { FormEvent, useEffect, useState } from "react"
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom"
import actions from "../../data/actions"
import { REGISTER_USER_ACTION } from "../../data/actionTypes"
import ExternalLogins from "./externals"
import { default_login } from '@/libs/layouts'
import { Intent } from '@blueprintjs/core'
import { LOGIN_MODE } from '@/libs/interfaces/layouts'

interface IRegisterFormProps {
    extend_params?: any
}
const RegisterForm = (props: IRegisterFormProps): React.ReactElement => {
    const [before,] = useState( true )
    const [email, setEmail] = useState<string>("") 
    const [passWord, setPassWord] = useState<string>("")
    const [first_name, setFirst_name] = useState<string>("") 
    const [secondName, setSecondName] = useState<string>("")
    const [checked, setChecked] = useState<boolean>(false)
    const location = useLocation()
    const navigate = useNavigate()  
    let interval: any = "-1"

    useEffect(() => {
        return () => {
            clearTimeout(interval)
        }
    }, [])

    const onSubmit = (evt : FormEvent<HTMLFormElement>) => {
        evt.preventDefault() 
        if(!email || !first_name || !secondName || !passWord || !checked ) {
            AppToaster.show({
                message: __("Not all required fields are filled in"),
                intent: Intent.DANGER
            })
            return
        }
        actions(REGISTER_USER_ACTION, {
            item: {
                user_email: email,
                first_name,
                last_name: secondName,
                display_name: `${first_name} ${secondName}`,
                password: passWord
            }
        }).then((resp) => {
            if(resp) {
                AppToaster.show({
                    className:"p-4",
                    message: __("User successfully create. Sign in please"),
                    timeout: 5000
                })
                setEmail("")
                setPassWord("")
                setFirst_name("")
                setSecondName("")
                setChecked(false)
                interval = setTimeout(() => {
                    navigate(location.state.pathname || "/")
                }, 300)
                
            }
        })
    }
    const onCallBack = () => {

    } 
    const before_register = () =>
    { 
        return <div className="">
            <div className="display-6 p-3 text-center">
                {__("Create account")}
            </div>
            <div className="flex-centered py-4">
                <ExternalLogins onCallBack={onCallBack} /> 
            </div>
            {
                (default_login() === LOGIN_MODE.LOGIN || props.extend_params?.force) &&
                    <form 
                        onSubmit={ onSubmit }
                        className="flex-centered flex-column p-md-5 px-3 py-4"
                    >
                        <div className="form-group justify-content-center d-flex flex-column w-100"> 
                            <input
                                type="text"
                                className="form-control input dark"
                                placeholder={__("Имя")}
                                value={ first_name}
                                onChange={ evt => setFirst_name( evt.target.value ) }
                            />
                        </div>

                        <div className="form-group justify-content-center d-flex flex-column w-100"> 
                            <input
                                type="text"
                                className="form-control input dark"
                                placeholder={__("Фамилия")}
                                value={ secondName}
                                onChange={ evt => setSecondName( evt.target.value ) }
                            />
                        </div>

                        <div className="form-group w-100"> 
                        <input
                            type="email"
                            className="form-control input dark"
                            value={ email }
                            placeholder={__("Эл. Почта")}
                            onChange={ evt => setEmail( evt.target.value ) }
                        />
                        </div> 

                        <div className="form-group w-100"> 
                            <input
                                type="password"
                                className="form-control input dark"
                                placeholder={__("Пароль")}
                                value={ passWord }
                                onChange={ evt => setPassWord( evt.target.value ) }
                            />
                        </div>

                        <div className="form-group form-check w-100">
                            <input
                                type="checkbox"
                                className="form-check-input"
                                onChange={ evt => setChecked( evt.target.checked ) }
                                checked={ checked}
                                id="exampleCheck1"
                            />
                            <label
                                className="form-check-label text-left"
                                htmlFor="exampleCheck1"
                            >
                                {__("Я согласен с ")}
                                <NavLink to="/usl" >
                                    {__("Условиями Пользовательского соглашения")}
                                </NavLink>
                            </label>
                        </div>
                        <input
                            type="submit"
                            className="btn btn-primary py-2 px-5 rounded-pill"
                            value={__("Sign up")}
                        />
                    </form>
            } 
        </div> 
    }

    const after_register = () =>
    {
        return <div className=" h-100 d-flex align-items-end w-100 p-5 ">
            <div className=" text-center " style={{maxWidth: 600 }}>
                <div className="title lead">
                    {__("Внимание! ")}
                </div>
                <div className="my-4">
                    {__("В ближайшие 10 минут Вы получите электронное письмо на адрес, который Вы указали при регистрации. Для завершения регистрации необходимо совершить последний шаг (инструкция в письме).")}
                </div>
                <Link to="/" className="btn btn-outline-danger  px-5 py-3 rounded-pill">
                    {__("Return to main page")}
                </Link>
            </div>
        </div> 
    }

    const no_register = () =>
    {
        return (
        <div className="row justify-content-center h-100 align-items-center">
            <div className="lead text-center col-md-6">
                {__("Для регистрации новой учётной записи необходимо разлогиниться.")}
            </div>
            <Link to="/" className="btn btn-danger btn-sm mt-5">{__("Return to main page")}</Link>
        </div>
        )
    } 
    return <div className="w-100 flex-grow-100 h-100">
        { before ? before_register() : after_register() }
    </div> 
}
 

export default RegisterForm

// class RegisterForm extends Component 
// {
//     state = { before: true };
//     email = (e) => { this.setState({ email: e.currentTarget.value }) }
//     phone = (e) => { this.setState({ phone: e.currentTarget.value }) }
//     passWord = (e) => { this.setState({ password: e.currentTarget.value }) }
//     onName = (e) => { this.setState({ display_name: e.currentTarget.value }) }
//     onSecondName = (e) => { this.setState({ secondName: e.currentTarget.value }) }
//     onChecked = (e) => { this.setState({ checked: !this.state.checked }) }
//     onVK = () =>
//     {

//     }
//     responseVk = () =>
//     {
        
//     }
//     onYandex = () =>
//     {
        
//     }
//     before_register = () =>
//     {
//         const mutation = gql`
//                 mutation registerUser($input: UserInput) {
//                     registerUser(input: $input)
//                 }`
//         return (
//         <>
//             <Mutation mutation={mutation}>
//                 {(m_change) => (
//                     <div className="">
//                         <div className="flex-centered pt-4">
//                             <VKLogin
//                                 apiId={ config?.vk_api_key }
//                                 value={"VK"}
//                                 fields="name,email,picture"
//                                 onClick={ this.onVK }
//                                 callback={ this.responseVk} 
//                                 className="wpfest-admin-cs mr-1"
//                             />
//                             <div className=" wpfest-admin-cs -hidden mr-1"> 
//                                 <div className="soc yandex wpfest-admin-cs " onClick={ this.onYandex }>
//                                     <i className="fab fa-yandex" />
//                                 </div> 
//                             </div>
//                             <div className=" wpfest-admin-cs -hidden mr-1"> 
//                                 <div className="soc telegram wpfest-admin-cs " onClick={ this.onYandex }>
//                                     <i className="fab fa-telegram-plane" />
//                                 </div> 
//                             </div>
//                             <div className=" wpfest-admin-cs -hidden mr-1"> 
//                                 <div className="soc odnoklassniki wpfest-admin-cs " onClick={ this.onYandex }>
//                                     <i className="fab fa-odnoklassniki" />
//                                 </div> 
//                             </div>
//                         </div>
//                         <form 
//                             onSubmit={(e) => { e.preventDefault(); this.onChange(this.state, m_change) }}
//                             className="flex-centered flex-column p-md-5 px-3 py-4"
//                         >
//                             <div className="form-group justify-content-center d-flex flex-column w-100"> 
//                                 <input
//                                     type="text"
//                                     className="form-control input dark"
//                                     placeholder={__("Имя")}
//                                     value={this.state.name}
//                                     onChange={this.onName}
//                                 />
//                             </div>

//                             <div className="form-group justify-content-center d-flex flex-column w-100"> 
//                                 <input
//                                     type="text"
//                                     className="form-control input dark"
//                                     placeholder={__("Фамилия")}
//                                     value={this.state.secondName}
//                                     onChange={this.onSecondName}
//                                 />
//                             </div>

//                             <div className="form-group w-100"> 
//                             <input
//                                 type="email"
//                                 className="form-control input dark"
//                                 value={this.state.emain}
//                                 placeholder={__("Эл. Почта")}
//                                 onChange={this.email}
//                             />
//                             </div>

//                             {/* <div className="form-group"> 
//                                 <input
//                                     type="phone"
//                                     className="form-control input dark"
//                                     placeholder={__("Введите номер телефона")}
//                                     value={this.props.phone}
//                                     onChange={this.phone}
//                                 />
//                             </div> */}

//                             <div className="form-group w-100"> 
//                                 <input
//                                     type="password"
//                                     className="form-control input dark"
//                                     placeholder={__("Пароль")}
//                                     onChange={this.passWord}
//                                 />
//                             </div>

//                             <div className="form-group form-check w-100">
//                                 <input
//                                     type="checkbox"
//                                     className="form-check-input"
//                                     onChange={this.onChecked}
//                                     checked={this.state.checked}
//                                     id="exampleCheck1"
//                                 />
//                                 <label
//                                     className="form-check-label text-left"
//                                     htmlFor="exampleCheck1"
//                                 >
//                                     {__("Я согласен с ")}
//                                     <NavLink
//                                     to="/usl"
//                                     >
//                                     {__("Условиями Пользовательского соглашения")}
//                                     </NavLink>
//                                 </label>
//                             </div>
//                             <input
//                                 type="submit"
//                                 className="btn btn-primary py-2 px-5 rounded-pill"
//                                 value={__("Sign up")}
//                             />
//                         </form>
//                     </div>
//                 )}
//             </Mutation>
//         </>        
//     )}

//     after_register = ()=>
//     {
//         return (
//         <div className=" h-100 d-flex align-items-end w-100 p-5 ">
//             <div className=" text-center " style={{maxWidth: 600 }}>
//                 <div className="title lead">
//                     {__("Внимание! ")}
//                 </div>
//                 <div className="my-4">
//                     {__("В ближайшие 10 минут Вы получите электронное письмо на адрес, который Вы указали при регистрации. Для завершения регистрации необходимо совершить последний шаг (инструкция в письме).")}
//                 </div>
//                 <Link to="/" className="btn btn-outline-danger  px-5 py-3 rounded-pill">
//                     {__("Return to main page")}
//                 </Link>
//             </div>
//         </div>
//         )
//     }

//     no_register = () =>
//     {
//         return (
//         <div className="row justify-content-center h-100 align-items-center">
//             <div className="lead text-center col-md-6">
//                 {__("Для регистрации новой учётной записи необходимо разлогиниться.")}
//             </div>
//             <Link to="/" className="btn btn-danger btn-sm mt-5">{__("Return to main page")}</Link>
//         </div>
//         )
//     }

//     render = () => {
//         // if (this.props.user) {
//         //   return this.no_register()
//         // }
//         return (
//         <div className="w-100 flex-grow-100 h-100">
//             { this.state.before ? this.before_register() : this.after_register()}
//         </div>
//         )
//     }

//     getRoute = () => "register"

//     getExtends = () => null

//     onChange = (state, m_change) => {
//         delete state.avatar
//         delete state.html
//         delete state.description
//         delete state.panelHtml
//         delete state.roles
//         delete state.route
//         delete state.sub
//         delete state.__typename
//         console.log(state)

//         if (this.state.email === "") {
//         AppToaster.show({
//             intent: Intent.DANGER,
//             icon: "error",
//             message: __("email not be empty"),
//         })
//         return
//         }
//         if (this.state.name === "") {
//         AppToaster.show({
//             intent: Intent.DANGER,
//             icon: "error",
//             message: __("name not be empty"),
//         })
//         return
//         }
//         // if(this.state.password === "")
//         // {
//         // 	AppToaster.show({
//         // 		intent: Intent.DANGER,
//         // 		icon: "error",
//         // 		message: __("password not be empty")
//         // 	});
//         // 	return;
//         // }

//         m_change({
//         variables:
//         {
//             input: {
//             user_login: state.login,
//             user_email: state.email,
//             first_name: state.display_name,
//             last_name: state.secondName,
//             password: state.password,
//             phone: state.phone,
//             },
//         },
//         update: (store, { data: { registerUser } }) => {
//             this.setState({ before: false })
//             AppToaster.show({
//             intent: Intent.SUCCESS,
//             icon: "tick",
//             message: __("Вы зарегистрированы! Проверьте свою электронную почту."),
//             })
//         },
//         })
//     }
// }
// export default compose(
//     withApollo,
//     withRouter,
// )(RegisterForm)
